<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog
    as="div"
    class="fixed z-100 inset-0 overflow-y-auto"
    @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <DialogOverlay
       class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <div
       class="sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all">
       <div class="">
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
         <DialogTitle
          as="h3"
          class="pb-2 text-lg leading-6 font-medium text-gray-900 space-y-1">
          <p class="self-center">{{ title }}</p>
         </DialogTitle>

         <div class="my-2 text-gray-500 space-y-4">
          <p class="text-sm text-gray-500 pt-2">
           {{ $t("Modifiez les destinataires de votre rapport par e-mail.") }}
          </p>
         </div>

         <!-- Email Input -->
         <form
          class="mt-0 sm:flex lg:mt-0"
          @submit.prevent="pushToEmails(email)">
          <div>
           <input
            v-model="email"
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            required
            class="shadow-sm block w-full sm:text-sm rounded-md border-gray-300"
            placeholder="you@example.com" />
          </div>
          <div class="mt-0 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
           <button
            type="submit"
            class="w-full border hover:bg-gray-100 border-gray-600 rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium">
            {{ $t("Ajouter un destinataire") }}
           </button>
          </div>
         </form>
         <div class="py-1" v-show="emails.length > 0">
          <div v-for="email in emails" :key="email" class="inline-flex mb-1">
           <div class="mx-1">
            <span
             class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-600">
             {{ email }}
             <button
              @click="removeEmailFromList(email)"
              type="button"
              class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
              <span class="sr-only">Remove option</span>
              <svg
               class="h-2 w-2"
               stroke="currentColor"
               fill="none"
               viewBox="0 0 8 8">
               <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7" />
              </svg>
             </button>
            </span>
           </div>
          </div>
         </div>
         <!-- Resource Group Dropdown -->
         <div class="mt-4">
          <label
           for="resource-group"
           class="block text-sm font-medium text-gray-700"
           >Groupe de ressources</label
          >
          <select
           v-model="pbx3cx_host_resource_group_id"
           id="resource-group"
           class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md">
           <option value="">None</option>
           <option
            v-for="group in resourceGroups"
            :key="group.id"
            :value="group.id">
            {{ group.name }}
           </option>
          </select>
         </div>
         <!-- DNS Input Field -->
         <div class="mt-4">
          <label for="dns" class="block text-sm font-medium text-gray-700"
           >Elements</label
          >
          <div class="mt-1 sm:mt-0 sm:col-span-2">
           <InputSearchSelector
            v-model:searchElement="searchElement"
            :searchData="
             element === 'extension'
              ? extensionsListSliced
              : element === 'queue'
              ? queueListSliced
              : DIDsListSliced
            "
            :infiniteHandler="infiniteHandler"
            :urlType="element"
            :addElement="addPbxElement"
            :selected="selectedPbxElements"
            :slicedListIncludes="slicedListIncludes"
            :elementListConcatReduce="
             element === 'extension'
              ? extensionsList
              : element === 'queue'
              ? queueList
              : DIDsList
            " />
          </div>
         </div>
        </div>
       </div>

       <!-- Action Buttons -->
       <div class="pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
         type="button"
         class="capitalize w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_primary encom_primary_hover text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
         @click="_confirm">
         {{ $t("validate") }}
        </button>
        <button
         type="button"
         class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
         @click="_cancel">
         {{ $t("ticket.cancel") }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import InputSearchSelector from "../InputSearchSelector.vue";
import PopupModal from "../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const timezone = localStorage.getItem("hostTimeZone");

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
  "elements",
  "startDate",
  "endDate",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   searchElement: "",
   id: null,
   cron: "",
   dataset: "",
   emails: [],
   dns: [],
   reportType: null,
   pbx3cx_host_resource_group_id: "",
   resourceGroups: [],
   reportEmails: [],
   email: "",
   invalidEmail: false,
   emailRegexError: false,
   emailAlreadyExistError: false,
   message: undefined, // Main text content
   nickname: "",
   title: undefined,
   isSchedule: false,
   hour: "",
   timezone,
   subMessage: undefined, // text content before input
   instant: false,
   startAt: new Date(),
   queueList: [],
   queueListSliced: [],
   extensionsList: [],
   extensionsListSliced: [],
   DIDsList: [],
   DIDsListSliced: [],
   elements: [],
   elementToRemove: "",
   sliceElements: 500,
   element: null,
   selectedPbxElements: [],
   openUpdateForm: true,
   group_id: null,
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
  Switch,
  SwitchGroup,
  SwitchLabel,
  InputSearchSelector,
 },
 methods: {
  addPbxElement(element) {
   this.pbx3cx_host_resource_group_id = "";
   if (!this.openUpdateForm) {
    if (element) {
     if (this.selectedPbxElements.length > 0) {
      if (
       this.selectedPbxElements.indexOf(element) >= 0 ||
       this.selectedPbxElements.indexOf(parseInt(element)) >= 0
      ) {
       this.selectedPbxElements.splice(
        this.selectedPbxElements.indexOf(element) >= 0
         ? this.selectedPbxElements.indexOf(element)
         : this.selectedPbxElements.indexOf(parseInt(element)),
        1
       );
      } else {
       this.selectedPbxElements.push(element);
      }
     } else {
      this.selectedPbxElements.push(element);
     }
    }
   } else {
    if (element) {
     if (this.selectedPbxElements.length > 0) {
      if (
       this.selectedPbxElements.indexOf(element.value) >= 0 ||
       this.selectedPbxElements.indexOf(parseInt(element.value)) >= 0
      ) {
       this.selectedPbxElements.splice(
        this.selectedPbxElements.indexOf(element.value) >= 0
         ? this.selectedPbxElements.indexOf(element.value)
         : this.selectedPbxElements.indexOf(parseInt(element.value)),
        1
       );
      } else {
       this.selectedPbxElements.push(element.value);
      }
     } else {
      this.selectedPbxElements.push(element.value);
     }
    }
   }
  },
  infiniteHandler($state) {
   setTimeout(
    function () {
     if (this.element === "extension") {
      if (this.extensionsListSliced.length == this.extensionsList.length) {
       $state.complete();
      } else {
       this.extensionsListSliced = this.extensionsListSliced.concat(
        this.extensionsList.slice(this.sliceElements - 500, this.sliceElements)
       );
       this.sliceElements = this.sliceElements + 500;
      }
     } else if (this.element === "did") {
      if (this.DIDsListSliced.length == this.DIDsList.length) {
       $state.complete();
      } else {
       this.DIDsListSliced = this.DIDsListSliced.concat(
        this.DIDsList.slice(this.sliceElements - 500, this.sliceElements)
       );
       this.sliceElements = this.sliceElements + 500;
      }
     } else {
      if (this.queueListSliced.length == this.queueList.length) {
       $state.complete();
      } else {
       this.queueListSliced = this.queueListSliced.concat(
        this.queueList.slice(this.sliceElements - 500, this.sliceElements)
       );
       this.sliceElements = this.sliceElements + 500;
      }
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  slicedListIncludes() {
   let flag = false;
   let list =
    this.element === "extension"
     ? this.extensionsListSliced
     : this.element === "queue"
     ? this.queueListSliced
     : this.DIDsListSliced;
   for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element.label.includes(this.searchElement)) {
     flag = true;
    }
   }
   return flag;
  },
  getPbxMap() {
   let obj = {};
   let objDID = {};
   let objQueue = {};
   let extensionsArray = [];
   let keysExtensions = Object.keys(this.pbxMap?.extensions);
   let keysDIDs = this.pbxMap?.dids;
   let keysQueue = Object.keys(this.pbxMap?.call_queues);
   keysExtensions.forEach((element, index) => {
    obj = {};
    obj["value"] = keysExtensions[index];
    obj["label"] =
     keysExtensions[index] + " - " + this.pbxMap?.extensions[element];
    obj["type"] = "extension";
    this.extensionsList.push(obj);
   });
   keysDIDs.forEach((element, index) => {
    objDID = {};
    objDID["value"] = element;
    objDID["label"] = element;
    objDID["type"] = "did";
    this.DIDsList.push(objDID);
   });
   keysQueue.forEach((element, index) => {
    objQueue = {};
    objQueue["value"] = keysQueue[index];
    objQueue["label"] =
     keysQueue[index] + " - " + this.pbxMap?.call_queues[element].name;
    objQueue["members"] = this.pbxMap?.call_queues[element].members;
    objQueue["type"] = "queue";
    this.queueList.push(objQueue);
   });
  },
  async fetchResourceGroups() {
   try {
    const response = await axios.get(
     `${localStorage.getItem("apiUrl")}/api/v1.2/${
      this.hostName
     }/resource-groups`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     }
    );
    console.log("response.data ==> ", response.data);
    this.resourceGroups = response.data.filter(
     res => res.type == this.reportType
    );
   } catch (error) {
    console.error("Error fetching resource groups", error);
   }
  },
  postReport(parameters, msg) {
   const options = {
    method: "PUT",
    url: `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/reports/${
     this.id
    }`,
    data: {
     email: this.emails.toString(),
     dns: this.selectedPbxElements.toString(),
     pbx3cx_host_resource_group_id: this.pbx3cx_host_resource_group_id || null,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
    },
   };

   axios
    .request(options)
    .then(res => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
     this.cleanForm();
    })
    .catch(err => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.response);
    });
  },
  cleanForm() {
   this.nickname = "";
   this.emails = [];
  },
  show(opts = {}) {
   this.getPbxMap();
   this.fetchResourceGroups().then(() => {
    this.pbx3cx_host_resource_group_id = opts.group;
   });

   this.okButton = opts.okButton;
   if (opts.cancelButton) {
    this.cancelButton = opts.cancelButton;
   }
   if (opts.action) {
    this.action = opts.action;
   }
   this.title = opts.title;
   this.id = opts.id;
   this.emails = opts.email.split(",");
   this.selectedPbxElements = opts.dns.split(",");
   this.reportType = opts.type;

   this.element =
    opts.type == 4 ? "queue" : opts.type == 0 ? "extension" : "did";
   // Once we set our config, we tell the popup modal to open
   this.$emit("closeReportList");
   this.$refs.popup.open();

   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  _confirm() {
   try {
    this.postReport();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },

  _cancel() {
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
  pushToEmails(email) {
   this.invalidEmail = false;
   this.emailRegexError = false;
   this.emailAlreadyExistError = false;

   if (this.validateEmail(email) && !this.emails.includes(email)) {
    this.emails.push(email);
    this.email = "";
   } else {
    this.invalidEmail = true;
    this.validateEmail(email)
     ? (this.emailRegexError = true)
     : (this.emailAlreadyExistError = true);
   }
  },
  removeEmailFromList(email) {
   let index = this.emails.indexOf(email);
   this.emails.splice(index, 1);
  },
  validateEmail(email) {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
  },
 },
 computed: {
  ...mapGetters([
   "pbxElements",
   "pbxTimeSelected",
   "pbxTimeSelectedEnd",
   "hostName",
   "user",
   "pbxMap",
  ]),
 },
 mounted() {},
 watch: {
  pbx3cx_host_resource_group_id: function (val) {
   if (val) {
    this.selectedPbxElements = [];
    this.selectedPbxElements = JSON.parse(
     this.resourceGroups?.filter(res => res.id == val)[0].resources
    );
   }
  },
 },
};
</script>

<style></style>
